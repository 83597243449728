.factionDiv {
    color: rgb(221, 221, 221);
    display: flex;
    flex-direction: column;
    background: #272727;
    width: 400px;
    margin: 10px;
    border-radius: 15px;
    max-height: 650px;
    border: 1px solid black;
    box-shadow: 4px 3px rgba(0, 0, 0, 0.1);
}

.factionDiv:hover {
    transform: scale(1.05);
    transition: all 0.3s ease;
    box-shadow: 6px 5px rgba(0, 0, 0, 0.2);
}

.factionImage {
    border-radius: 15px 15px 0 0;
    height: 250px;
    margin-bottom: 10px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    border-bottom: 1px solid black;
}

.factionInfoDiv {
    display: flex;
    flex-direction: column;
    max-height: 250px;
    flex: 1;
}

.factionInfoLabel {
    padding: 0 15px;
    font-size: 27px;
    justify-self: flex-start;
}

.factionInfoDescription {
    font-size: 18.4px;
    padding: 5px 15px;
    max-height: 140px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 6;
}

.factionInfoCTA {
    background-color: #018786;
    color: white;
    font-size: 18px;
    text-decoration: none;
    padding: 15px 25px;
    margin: 15px 30px;
    border-radius: 7px;
    text-align: center;
}

.factionInfoCTA:hover {
    cursor:pointer;
    background-color: #00a8a8;
}