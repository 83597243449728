.aboutContainer {
    margin-top: 100px;
    margin-bottom: 100px;
    color: white;
}

.aboutContent {
    margin: 0 auto;
    max-width: 50%;
}

.imagesHeader {
    text-align: center;
    padding-bottom: 10px;
}

.aboutMenuImages {
    text-align: center;
    margin: 0 auto;
    padding-bottom: 15px;
}

@media (max-width: 800px) {
    .aboutContent {
        max-width: 100%;
    }
}