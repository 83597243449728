.tableContainer {
    color: rgb(221, 221, 221);
    background: #272727;
    max-width: 80%;
    text-align: center;
    margin: 0 auto;
    padding: 20px 30px;
    border: 1px solid black;
    border-radius: 20px;
    
    margin-top: 100px;
    margin-bottom: 100px;
}

.tableContainer h3 {
    text-align: center;
    font-size: 25px;
    padding-bottom: 15px;
}

@media (max-width: 800px) {
    .tableContainer h3 {
        font-size: 20px;
    }
}